<template>
  <v-form ref="form" v-model="validInput">
    <v-row
      ><v-col cols="12" md="12"
        >Testrun wil be added for '<b>{{ addRunItem ? addRunItem.Name : '' }}</b
        >'</v-col
      ><v-col cols="12" md="3"
        ><v-text-field
          outlined
          label="Number of runs"
          dense
          hide-details
          type="number"
          :disabled="isRunCreating"
          v-model="addNumber"
          :rules="[rules.required, rules.number]"
          @change="$emit('nrOfRunsChanged', addNumber)"
        >
        </v-text-field></v-col
    ></v-row>
    <v-row
      ><v-col>
        <v-expansion-panels accordion v-model="activePanels" multiple>
          <v-expansion-panel
            :readonly="true"
            v-for="(department, index) in TestData"
            :key="index"
          >
            <v-expansion-panel-header>
              <template v-slot:actions
                ><span
                  class="text-caption"
                  :style="{
                    width: '30px',
                  }"
                  >({{ department.testLines.length }})</span
                >

                <v-icon @click.stop="togglePanel(index)" color="primary">
                  $expand
                </v-icon>
              </template>
              <v-row align="center"
                ><v-col cols="12" md="3">
                  <span>{{ department.name }}</span></v-col
                ><v-col cols="12" md="4">
                  <v-menu
                    v-model="department.showMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="
                          department.DueDate
                            ? new Date(department.DueDate).toLocaleDateString()
                            : null
                        "
                        label="Due Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="department.DueDate"
                      no-title
                      locale="nl-be"
                      @input="department.showMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="department.Assignees"
                    :items="users"
                    item-text="fullname"
                    item-value="id"
                    label="Assignees"
                    clearable
                    hide-details
                    multiple
                    outlined
                    dense
                    v-bind:search-input.sync="searchUser"
                    @input="clearSearch"
                    @blur="assignMaxRuns"
                    ><template v-slot:item="{ item }">
                      <UserAvatar
                        :Name="item.fullname"
                        :Image="item.gravatarUrl"
                        :Color="item.color"
                      />
                      {{ item.fullname }}</template
                    ><template #selection="{ item }">
                      <UserAvatar
                        :Name="item.fullname"
                        :Image="item.gravatarUrl"
                        :Color="item.color"
                      /> </template></v-autocomplete
                ></v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li v-for="(line, index) in department.testLines" :key="index">
                  {{ line.Name }}
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col></v-row
    >
    <v-row
      ><v-col class="mx-5">
        <v-progress-linear
          v-show="isRunCreating"
          class="mt-5"
          :value="(addedCount / addNumber) * 100"
        ></v-progress-linear></v-col
    ></v-row>
    <v-progress-linear
      v-if="showProgress"
      class="mt-5"
      :value="(addedCount / addNumber) * 100"
    ></v-progress-linear>
  </v-form>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { handleErrorResponse } from '@/utils/MessageHandler';
import UserAvatar from '@/components/general/UserAvatar.vue';

export default {
  props: {
    addRunItem: {
      type: Object,
      required: true,
    },
  },
  components: {
    UserAvatar,
  },
  mixins: [
    makeFindMixin({ service: 'test-scenario-line', watch: 'addRunItem.id' }),
    makeFindMixin({ service: 'department' }),
    makeFindMixin({ service: 'users' }),
  ],
  watch: {
    isFindTestScenarioLinePending: {
      handler() {
        if (!this.isFindTestScenarioLinePending) {
          if (this.testScenarioLine.length === 0) return [];

          let result = [];
          let currentDepartmentId = null;
          let currentGroup = null;

          for (const line of this.testScenarioLine) {
            const departmentId = line.variant.process.DepartmentId;

            if (departmentId !== currentDepartmentId) {
              if (currentGroup) {
                result.push(currentGroup);
              }
              currentGroup = {
                id: departmentId,
                name: this.department.find((f) => f.id == departmentId)?.Name,
                testLines: [],
              };
              currentDepartmentId = departmentId;
            }

            currentGroup.testLines.push(line);
          }

          // Push the last group
          if (currentGroup) {
            result.push(currentGroup);
          }

          this.TestData = result;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      validInput: true,
      addNumber: 1,
      addedCount: null,
      isRunCreating: false,
      TestData: [],
      dateMenu: [],
      showProgress: false,
      activePanels: [],
      searchUser: '',
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value?.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
    };
  },
  computed: {
    testScenarioLineParams() {
      return {
        query: {
          TestScenarioHeaderId: this.addRunItem.id,
          $sort: { Order: 1 },
        },
      };
    },
    departmentParams() {
      return {};
    },
    usersParams() {
      return { query: { Active: true, $sort: { fullname: 1 } } };
    },

    // groupedDepartmentList() {
    //   if (this.testScenarioLine.length === 0) return [];

    //   let result = [];
    //   let currentDepartmentId = null;
    //   let currentGroup = null;

    //   for (const line of this.testScenarioLine) {
    //     const departmentId = line.variant.process.DepartmentId;

    //     if (departmentId !== currentDepartmentId) {
    //       if (currentGroup) {
    //         result.push(currentGroup);
    //       }
    //       currentGroup = {
    //         id: departmentId,
    //         name: this.department.find((f) => f.id == departmentId)?.Name,
    //         testLines: [],
    //       };
    //       currentDepartmentId = departmentId;
    //     }

    //     currentGroup.testLines.push(line);
    //   }

    //   // Push the last group
    //   if (currentGroup) {
    //     result.push(currentGroup);
    //   }

    //   return result;
    // },
  },
  methods: {
    togglePanel(index) {
      const panelIndex = this.activePanels.indexOf(index);
      if (panelIndex === -1) {
        this.activePanels.push(index); // Open panel
      } else {
        this.activePanels.splice(panelIndex, 1); // Close panel
      }
    },
    clearSearch() {
      this.searchUser = '';
    },
    formatDueDate(date) {
      if (!date) return ''; // Handle empty case

      return new Date(date).toLocaleDateString('nl-BE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
    assignMaxRuns() {
      this.addNumber = this.TestData.reduce((maxLength, line) => {
        const assigneesLength = line?.Assignees?.length || 0;
        return Math.max(maxLength, assigneesLength);
      }, 0);
      this.$emit('nrOfRunsChanged', this.addNumber);
    },
    async clickAddRuns() {
      this.$emit('createRun', true);
      this.showProgress = true;

      for (let i = 0; i < this.addNumber; i++) {
        await this.clickRun(i);
        this.addedCount = i + 1;
      }
      this.addedCount = null;
      this.showProgress = false;
      this.$emit('createRun', false);
      this.$emit('closeDialog', false);
      location.reload();
    },

    async clickRun(runIndex) {
      let item = this.addRunItem;
      // Create New Test Run
      const {
        Variant,
        TestScenarioRun,
        TestScenarioLine,
        TestScenarioRunDetail,
        TestScenarioRunSteps,
        TestScenarioLineStep,
        TestScenarioRunFieldValues,
        TestScenarioLineStepField,
      } = this.$FeathersVuex.api;
      let newRun = new TestScenarioRun();
      newRun.Number = item.Number;
      newRun.Name = item.Name;
      newRun.Description = item.Description;
      newRun.TestScenarioHeaderId = item.id;
      newRun.TestScenarioTypeId = item.TestScenarioTypeId;

      try {
        newRun = await newRun.save();
      } catch (error) {
        handleErrorResponse(error);
      }

      // Get Lines
      const testLines = await TestScenarioLine.find({
        query: { TestScenarioHeaderId: item.id },
      });
      if (testLines.total > 0) {
        // Create Run Detail for each Test Line
        for (const line of testLines.data) {
          // departmetn Info
          const variant = await Variant.find({
            query: { id: line.VariantId },
          });

          // find duedate and assignees
          const metaData = this.TestData.find((group) =>
            group.testLines.some((t) => t.id === line.id)
          );

          let assignee = null;
          if (metaData.Assignees && metaData.Assignees.length > 0) {
            assignee = metaData.Assignees[runIndex % metaData.Assignees.length];
          }

          let newRunDetail = new TestScenarioRunDetail();
          newRunDetail.Order = line.Order;
          // newRunDetail.Number = line.test_scenario.Number;
          newRunDetail.Name = line.Name;
          newRunDetail.ReferenceName = line.ReferenceName;
          newRunDetail.Description = line.Description;
          newRunDetail.TestScenarioRunId = newRun.id;
          // newRunDetail.TestScenarioId = line.test_scenario.id;
          newRunDetail.TestScenarioLineId = line.id;
          newRunDetail.Detailed = line.Detailed;
          newRunDetail.DepartmentId =
            variant.total > 0 ? variant.data[0].process.DepartmentId : null;
          newRunDetail.DueDate = metaData.DueDate;
          newRunDetail.AssignedUserId = assignee;

          try {
            newRunDetail = await newRunDetail.save();
          } catch (error) {
            handleErrorResponse(error);
          }

          // Get Steps
          const testSteps = await TestScenarioLineStep.find({
            query: { TestScenarioLineId: line.id, Selected: true },
          });
          if (testSteps.total > 0) {
            // Create Run DEtail/Steps detail for eacht line/steps}
            for (const step of testSteps.data) {
              let newRunStep = new TestScenarioRunSteps();
              newRunStep.Number = step.Number;
              newRunStep.Name = step.process_step.Name; //step.Name;
              newRunStep.TestRemark = step.TestRemark;
              newRunStep.ExpectedResults = step.ExpectedResults;
              newRunStep.GroupId = step.GroupId;
              newRunStep.GroupIteration = step.GroupIteration;
              newRunStep.Done = false;
              newRunStep.TestScenarioRunDetailId = newRunDetail.id;
              newRunStep.TestScenarioLineStepId = step.id;
              newRunStep.ProcessStepId = step.process_step.id;
              newRunStep.Groups = step.Groups;
              newRunStep.Key = step.Key;
              try {
                newRunStep = await newRunStep.save();
              } catch (error) {
                handleErrorResponse(error);
              }

              // Get Fields
              const testFields = await TestScenarioLineStepField.find({
                query: { TestScenarioLineStepId: step.id },
              });

              if (testFields.total > 0) {
                // Create Run Steps/Fields detail for eacht line/steps/fields
                for (const field of testFields.data) {
                  let newRunField = new TestScenarioRunFieldValues();
                  newRunField.Field = field.fieldvaluelist.field.Name;
                  newRunField.Name = field.fieldvaluelist.Name;
                  newRunField.Code = field.fieldvaluelist.Code;
                  newRunField.Name = field.fieldvaluelist.Value;
                  newRunField.TestScenarioRunDetailId = newRunDetail.id;
                  newRunField.TestScenarioRunStepsId = newRunStep.id;

                  try {
                    newRunField = await newRunField.save();
                  } catch (error) {
                    handleErrorResponse(error);
                  }
                }
              }
            }
          }
        }
        await TestScenarioRun.find({ query: { id: newRun.id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
