<template>
  <span class="mx-10">
    <span v-if="iconOnly">
      <v-tooltip bottom v-if="drawingUrl && drawings?.length > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :size="iconSize"
            v-bind="attrs"
            v-on="on"
            @click="openWindow(drawingUrl)"
          >
            mdi-image-search-outline
          </v-icon>
        </template>
        <span>Process Flows</span>
      </v-tooltip>
    </span>
    <div v-else>
      <v-row>
        <!-- https://draw.loxi.be/?embed=1&configure=1&saveAndExit=1&returnbounds=1&proto=json&spin=1&clibs=Uhttps://raw.githubusercontent.com/lodeb/LOXI/main/webclient/public/DrawIO/LoxiTemplate.xml?token=GHSAT0AAAAAACSSH265BP2QIQVKS6GPDWNWZTL54JA' -->
        <!-- https://draw.loxi.be/?embed=1&configure=1&saveAndExit=1&returnbounds=1&proto=json&spin=1&clibs=Uhttps://loxi.eu-central-1.linodeobjects.com/DrawIO/LoxiTemplate.xml -->
        <iframe
          ref="DrawIOWindow"
          v-if="editMode"
          frameborder="0"
          style="
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            z-index: 999999;
          "
          :src="$store.state.drawioTemplateUrl"
        ></iframe>
        <iframe
          v-if="!editMode && !noImage && !fullscreen"
          @click="toggleEditMode"
          frameborder="0"
          :style="`width: 100%; height: ${height} ; border: none`"
          :src="'https://draw.loxi.be/?lightbox=1&spin=1#R' + base64Url"
        ></iframe>
        <iframe
          v-if="!editMode && !noImage && fullscreen"
          @click="toggleEditMode"
          frameborder="0"
          style="
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            z-index: 999999;
          "
          :src="'https://draw.loxi.be/?lightbox=1&spin=1#R' + base64Url"
        ></iframe>
      </v-row>
      <v-row v-if="!editMode">
        <v-spacer></v-spacer>
        <v-icon @click="toggleEditMode">
          {{
            noImage ? 'mdi-image-plus-outline' : 'mdi-image-edit-outline'
          }}</v-icon
        >
        <v-icon class="ml-2" v-if="!noImage" @click="openWindow(drawingUrl)">
          mdi-image-filter-none
        </v-icon>
        <v-icon
          v-if="
            (($can('update', 'process-step', 'Name') && variantId) ||
              ($can('update', 'variant', 'Name') && processId)) &&
            !noImage
          "
          class="ml-2"
          @click="showSyncerManual()"
        >
          mdi-table-sync
        </v-icon>
        <v-icon class="ml-2" @click="confirmDelete" v-if="!noImage"
          >mdi-delete</v-icon
        >
      </v-row>
    </div>
    <v-dialog :value="showRemoveDialog" persistent max-width="600px">
      <v-card flat align="center" class="pa-5">
        <v-card-title
          ><v-icon color="red" class="ml-2">mdi-alert</v-icon>Remove
          Image</v-card-title
        >
        <v-card-text
          >Image will be permanently removed. Are you sure?
        </v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer>
          <v-btn outlined @click="showRemoveDialog = false">Cancel</v-btn>
          <v-btn outlined @click="deleteImage">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DrawingSyncer
      v-if="showSyncer"
      v-model="showSyncer"
      :auto="autoSaveSyncer"
      :drawingXML="this.drawings[0]?.data"
      :drawingData="parseXML(drawings[0]?.data)"
      :process="processGet"
      :variant="variantGet"
    />
  </span>
</template>

<script>
import pako from 'pako';
import { makeFindMixin, makeGetMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import DrawingSyncer from './DrawingSyncer.vue';

import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

export default {
  props: {
    height: {
      type: String,
      required: false,
      default: '500px',
    },
    processId: {
      type: Number,
      required: false,
    },
    end2EndId: {
      type: Number,
      required: false,
    },
    variantId: {
      type: Number,
      required: false,
    },
    testScenarioHeaderId: {
      type: Number,
      required: false,
    },
    fullscreen: {
      type: Boolean,
      required: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
    iconSize: {
      type: String,
      required: false,
    },
    highlightVariantId: {
      type: Number,
      required: false,
    },
  },
  components: { DrawingSyncer },
  data() {
    return {
      editMode: false,
      showRemoveDialog: false,
      autoSaveSyncer: false,
      showSyncer: false,
      config: {
        language: 'fr',
        configVersion: null,
        customFonts: [
          {
            name: 'Roboto',
            url: 'https://fonts.googleapis.com/css?family=Roboto',
          },
        ],
        libraries: 'general;uml;er;bpmn;flowchart;basic;arrows2',
        customLibraries: ['L.scratchpad'],
        plugins: [],
        recentColors: [],
        formatWidth: '240',
        createTarget: false,
        pageFormat: {
          x: 0,
          y: 0,
          width: 827,
          height: 1169,
        },
        search: true,
        showStartScreen: true,
        gridColor: '#d0d0d0',
        darkGridColor: '#424242',
        autosave: true,
        resizeImages: null,
        openCounter: 76,
        version: 18,
        unit: 1,
        isRulerOn: false,
        ui: '',
        sketchMode: false,
        darkMode: 'auto',
      },
    };
  },
  mixins: [
    makeFindMixin({
      service: 'status',
    }),
    makeFindMixin({
      service: 'drawings',
      watch: ['processId', 'end2EndId', 'variantId', 'testScenarioHeaderId'],
    }),
    makeGetMixin({
      service: 'process',
      name: 'processGet',
      id: 'processId',
    }),
    makeGetMixin({
      service: 'variant',
      name: 'variantGet',
      id: 'variantId',
    }),
    makeGetMixin({
      service: 'variant',
      name: 'highlightVariantGet',
      id: 'highlightVariantId',
      watch: ['highlightVariantId'],
    }),
  ],

  computed: {
    base64Url() {
      try {
        const data = encodeURIComponent(this.XMLManipulator());
        const compressed = pako.deflateRaw(data);

        // Safer string conversion
        const charData = Array.from(new Uint8Array(compressed))
          .map((byte) => String.fromCharCode(byte))
          .join('');

        // Base64 encode
        return btoa(charData);
      } catch (error) {
        console.error('Error in base64Url:', error);
        return null;
      }
    },

    drawingUrl() {
      if (this.variantId) {
        return `${this.$appConfig.clientBaseUrl}/#/drawing?variant=${this.variantId}`;
      } else if (this.processId) {
        return `${this.$appConfig.clientBaseUrl}/#/drawing?process=${this.processId}&variantHighlight=${this.highlightVariantId}`;
      } else if (this.end2EndId) {
        return `${this.$appConfig.clientBaseUrl}/#/drawing?end2end=${this.end2EndId}`;
      } else if (this.testScenarioHeaderId) {
        return `${this.$appConfig.clientBaseUrl}/#/drawing?testScenarioHeader=${this.testScenarioHeaderId}`;
      } else {
        return null;
      }
    },
    drawingsParams() {
      return {
        query: {
          $or: [
            { ProcessId: this.processId },
            { End2EndId: this.end2EndId },
            { VariantId: this.variantId },
            { TestScenarioHeaderId: this.testScenarioHeaderId },
          ],
        },
      };
    },
    statusParams() {
      return {};
    },

    noImage() {
      return (
        this.drawings == null ||
        this.drawings?.total == 0 ||
        this.drawings?.length == 0
      );
    },
  },
  watch: {
    // processId: {
    //   handler: async function () {
    //     this.$refs.DrawIOWindow?.contentWindow?.postMessage(
    //       this.drawings[0]?.data,
    //       '*'
    //     );
    //   },
    // },
  },
  methods: {
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    toggleEditMode() {
      this.editMode = true;
    },
    confirmDelete() {
      this.showRemoveDialog = true;
    },
    showSyncerManual() {
      this.autoSaveSyncer = false;
      this.showSyncer = true;
    },
    XMLManipulator() {
      let xml = this.drawings[0]?.data;

      if (this.processGet) {
        if (this.highlightVariantGet?.ExternalId) {
          const settings = this.$store?.state?.globalSettings;
          let color = '#FF0000';
          if (settings?.length > 0) {
            color = settings.find((f) => f.key == 'HighlightColor')?.value;
          }
          xml = this.highlightStep(
            xml,
            this.highlightVariantGet?.ExternalId,
            color ? color : '#FF0000'
          );
        }
        for (const variant of this.processGet.variants.filter(
          (f) => f.ExternalId?.length > 0
        )) {
          if (variant.group) {
            xml = this.groupUpdater(
              xml,
              variant.ExternalId,
              variant.group.Color
            );
          }

          xml = this.linkupdater(
            xml,
            variant.ExternalId,
            this.$appConfig.clientBaseUrl + '/#/processStep/' + variant.id
          );
          xml = this.updateLabel(xml, variant.ExternalId, variant.Number);
        }
      }
      if (this.variantGet?.process_steps) {
        /// update groupcolors
        for (const step of this.variantGet.process_steps) {
          if (step.group)
            xml = this.groupUpdater(xml, step.ExternalId, step.group.Color);

          const StatusCategory = this.status.find(
            (f) => f.id == step.StatusId
          )?.StatusCategory;

          let fillColor = this.status.find((f) => f.id == step.StatusId)?.Color;
          if (StatusCategory) {
            if (StatusCategory == 'Open') {
              fillColor = '#BABABA';
            } else if (StatusCategory == 'In Progress') {
              fillColor = '#E55049';
            } else if (StatusCategory == 'Done') {
              fillColor = '#B1C95E';
            } else {
              fillColor = '#FFFFFF';
            }
          }

          xml = this.updateFillColor(xml, step.ExternalId, fillColor);
          if (step.id == this.highlightVariantId) {
            const settings = this.$store?.state?.globalSettings;
            let color = '#FF0000';
            if (settings?.length > 0) {
              color = settings.find((f) => f.key == 'HighlightColor')?.value;
            }
            xml = this.highlightStep(
              xml,
              step.ExternalId,
              color ? color : '#FF0000'
            );
          }
        }
      }

      return xml;
      // return this.drawings[0]?.data;
    },
    highlightStep(xml, id, color) {
      //;strokeColor=#aa1d1d;strokeWidth=11;

      // Parse the XML string into a DOM object
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xml, 'application/xml');

      // Find the UserObject with a specific id
      const userObject = xmlDoc.querySelector(`UserObject[id='${id}']`);
      const object = xmlDoc.querySelector(`object[id='${id}']`);

      if (userObject || object) {
        // Find the mxCell child element of the found UserObject
        const mxCell = userObject
          ? userObject.querySelector('mxCell')
          : object.querySelector('mxCell');

        if (mxCell) {
          // Modify the style attribute to add/update the strokeColor
          let style = mxCell.getAttribute('style');
          const newFillColor = `fillColor=${color};`;
          const styleRegex = /fillColor=[^;]+;/;
          const gradientColorRegex = /gradientColor=[^;]+;/;

          if (styleRegex.test(style)) {
            // Update existing fillColor
            style = style.replace(styleRegex, newFillColor);
          } else {
            // Add new fillColor
            style += newFillColor;
          }

          // Remove gradientColor if it exists
          if (gradientColorRegex.test(style)) {
            style = style.replace(gradientColorRegex, '');
          }

          mxCell.setAttribute('style', style);
        } else {
          return xml;
        }
      } else {
        return xml;
      }

      // Serialize the modified XML back to a string
      const serializer = new XMLSerializer();
      const updatedXmlString = serializer.serializeToString(xmlDoc);
      return updatedXmlString;
    },
    groupUpdater(xml, id, color) {
      //;strokeColor=#aa1d1d;strokeWidth=11;

      // Parse the XML string into a DOM object
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xml, 'application/xml');

      // Find the UserObject with a specific id
      const userObject = xmlDoc.querySelector(`UserObject[id='${id}']`);
      const object = xmlDoc.querySelector(`object[id='${id}']`);

      if (userObject || object) {
        // Find the mxCell child element of the found UserObject
        const mxCell = userObject
          ? userObject.querySelector('mxCell')
          : object.querySelector('mxCell');

        if (mxCell) {
          // Modify the style attribute to add/update the strokeColor
          let style = mxCell.getAttribute('style');
          const newStrokeColor = `strokeColor=${color};strokeWidth=5;'`; // New stroke color value
          const styleRegex = /strokeColor=[^;]+;/;

          if (styleRegex.test(style)) {
            // Update existing strokeColor
            style = style.replace(styleRegex, newStrokeColor);
          } else {
            // Add new strokeColor
            style += newStrokeColor + ';strokeWidth=5;';
          }

          mxCell.setAttribute('style', style);
        } else {
          return xml;
        }
      } else {
        return xml;
      }

      // Serialize the modified XML back to a string
      const serializer = new XMLSerializer();
      const updatedXmlString = serializer.serializeToString(xmlDoc);
      return updatedXmlString;
    },
    linkupdater(xml, id, newLink) {
      // Parse the XML string into a DOM object
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xml, 'application/xml');

      // Find the userobject with the specified id
      const userobject = xmlDoc.querySelector(`UserObject[id="${id}"]`);
      const object = xmlDoc.querySelector(`object[id="${id}"]`);

      if (userobject) {
        // Update or add the link attribute
        userobject.setAttribute('link', newLink);

        // Serialize the DOM object back to an XML string
        const serializer = new XMLSerializer();
        const updatedXmlString = serializer.serializeToString(xmlDoc);

        return updatedXmlString;
      } else if (object) {
        // Update or add the link attribute
        object.setAttribute('link', newLink);

        // Serialize the DOM object back to an XML string
        const serializer = new XMLSerializer();
        const updatedXmlString = serializer.serializeToString(xmlDoc);

        return updatedXmlString;
      } else return xml;
    },
    updateFillColor(xmlString, id, fillColor) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlString, 'application/xml');

      // Step 2: Find the object element with the given id
      const targetObject = xmlDoc.querySelector(`object[id="${id}"]`);
      if (!targetObject) {
        // console.log(`No object found with id ${id}`);
        return xmlString;
      }

      // Step 3: Retrieve the parent attribute from the underlying mxCell
      const targetMxCell = targetObject.querySelector('mxCell');
      if (!targetMxCell) {
        // console.log(`No mxCell found for object with id ${id}`);
        return xmlString;
      }

      const parentId = targetMxCell.getAttribute('parent');

      // Step 4: Find the object of Type="Status" with the same parent
      const statusObject = Array.from(
        xmlDoc.querySelectorAll('object[Type="Status"]')
      ).find((obj) => {
        const mxCell = obj.querySelector('mxCell');
        return mxCell && mxCell.getAttribute('parent') === parentId;
      });

      if (!statusObject) {
        // console.log(`No Status object found with parent id ${parentId}`);
        return xmlString;
      }

      // Step 5: Modify the fillColor in the style attribute
      const statusMxCell = statusObject.querySelector('mxCell');
      if (statusMxCell) {
        let style = statusMxCell.getAttribute('style');
        if (style) {
          // Update the fillColor while keeping other attributes
          const newStyle = style
            .split(';')
            .map((attr) => {
              return attr.startsWith('fillColor=')
                ? `fillColor=${fillColor}`
                : attr;
            })
            .join(';');
          statusMxCell.setAttribute('style', newStyle);
        }
      }

      // Convert the modified XML back to string
      const serializer = new XMLSerializer();
      const modifiedXml = serializer.serializeToString(xmlDoc);
      return modifiedXml;
    },
    updateLabel(xmlString, givenId, newNumber) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlString, 'text/xml');

      // Find the object with the given ID
      const targetObject = xmlDoc.querySelector(`[id='${givenId}']`);
      if (!targetObject) {
        // console.log('Object with given ID not found');
        return xmlString;
      }
      const targetMxCell = targetObject.querySelector('mxCell');
      if (!targetMxCell) return xmlString;

      const parent = targetMxCell.getAttribute('parent');

      const processNumberObject = Array.from(
        xmlDoc.querySelectorAll('object[Type="ProcessNumber"]')
      ).find((obj) => {
        const mxCell = obj.querySelector('mxCell');
        return mxCell && mxCell.getAttribute('parent') === parent;
      });

      if (!processNumberObject) {
        // console.log(`No processNumber object found with parent id ${parent}`);
        return xmlString;
      }

      if (processNumberObject) {
        let label = processNumberObject.getAttribute('label');

        const updatedLabel = label.replace(
          /<font style="font-size: 11px;">\d+<\/font>/,
          `<font style="font-size: 11px;">${newNumber}</font>`
        );
        processNumberObject.setAttribute('label', updatedLabel);
      }

      // Serialize the XML back to a string
      const serializer = new XMLSerializer();
      return serializer.serializeToString(xmlDoc);
    },
    parseXML(xmlString) {
      // Parse the XML string into a DOM object
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlString, 'application/xml');

      // Select all <object> elements
      let objectElements = [];
      objectElements.push(...xmlDoc.getElementsByTagName('UserObject'));
      objectElements.push(...xmlDoc.getElementsByTagName('object'));

      // Extract the label, Type, id attributes, and coordinates from each <object> element
      const objects = [];
      for (let obj of objectElements) {
        let label = obj.getAttribute('label');

        if (label === '') {
          // Find the mxCell where the parent ID matches the UserObject ID
          const matchingMxCells = xmlDoc.querySelectorAll(
            `mxCell[parent="${obj.getAttribute('id')}"]`
          );
          // console.log(matchingMxCells, obj);
          for (let mxCell of matchingMxCells) {
            const value = mxCell.getAttribute('value');
            if (value !== '') {
              label = value;
              break;
            }
          }
        }

        const type = obj.getAttribute('Type');
        const id = obj.getAttribute('id');

        // Remove HTML tags from the label
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = label;
        label = tempDiv.textContent || tempDiv.innerText || '';

        // Find the mxGeometry child element
        const mxCell = obj.getElementsByTagName('mxCell')[0];
        const mxGeometry = mxCell
          ? mxCell.getElementsByTagName('mxGeometry')[0]
          : null;
        const x = mxGeometry ? parseFloat(mxGeometry.getAttribute('x')) : null;
        const y = mxGeometry ? parseFloat(mxGeometry.getAttribute('y')) : null;

        objects.push({ label, type, id, x, y });
      }

      objects.sort((a, b) => {
        if (a.y !== b.y) {
          return a.y - b.y;
        }
        return a.x - b.x;
      });

      return objects;
    },
    async deleteImage() {
      try {
        await feathersClient.service('drawings').remove(this.drawings[0].id);
        handleSaveResponse('', 'Image', 'deleted');
      } catch (error) {
        handleErrorResponse(error);
      }
      this.showRemoveDialog = false;
    },
    async receiveMessage(evt) {
      if (
        evt.origin === 'https://draw.loxi.be' ||
        evt.origin === 'https://embed.diagrams.net'
      ) {
        console.log(evt);
        let EventData = null;
        try {
          EventData = JSON.parse(evt.data);
        } catch {
          //
        }

        if (EventData?.event == 'configure') {
          this.$refs.DrawIOWindow?.contentWindow?.postMessage(
            JSON.stringify({
              action: 'configure',
              config: {
                //defaultFonts: ['koko'],
                // defaultFonts: [
                //   {
                //     name: 'Roboto',
                //     url: 'https://fonts.googleapis.com/css?family=Roboto',
                //   },
                // ],

                language: 'fr',
                ui: 'min',
                defaultGridEnabled: false,
              }, //this.config,
            }),

            '*'
          );
        } else if (EventData?.event == 'init') {
          this.$refs.DrawIOWindow?.contentWindow?.postMessage(
            JSON.stringify({
              action: 'load',
              xml: this.drawings[0]?.data,
            }),

            '*'
          );
        } else if (EventData?.event == 'save') {
          var eventData = JSON.parse(evt.data);
          if (this.noImage) {
            try {
              await feathersClient.service('drawings').create({
                ProcessId: this.processId,
                End2EndId: this.end2EndId,
                VariantId: this.variantId,
                TestScenarioHeaderId: this.testScenarioHeaderId,
                data: eventData.xml,
              });
              handleSaveResponse('', 'Image', 'updated');
            } catch (error) {
              handleErrorResponse(error);
            }
            if (eventData.exit == true) {
              this.editMode = false;
            }
          } else {
            try {
              await feathersClient
                .service('drawings')
                .patch(this.drawings[0].id, { data: eventData.xml });
              handleSaveResponse('', 'Image', 'updated');
            } catch (error) {
              handleErrorResponse(error);
            }
            if (eventData.exit == true) {
              this.editMode = false;
            }
          }
          this.autoSaveSyncer = true;
          this.showSyncer = true;
        } else if (EventData?.event == 'exit') {
          this.editMode = false;
          // this.autoSaveSyncer = true;
          // this.showSyncer = true;
        }
      }
    },
  },
  mounted() {
    if (!this.iconOnly && !this.fullscreen) {
      window.addEventListener('message', this.receiveMessage);
    }
  },
  beforeDestroy() {
    if (!this.iconOnly && !this.fullscreen) {
      window.removeEventListener('message', this.receiveMessage);
    }
  },
};
</script>

<style scoped>
iframe {
  border: none;
}
</style>
