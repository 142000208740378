var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"1100px"}},[(_vm.isPending)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"secondary"}}):_vm._e(),(_vm.item && !_vm.isFindStatusPending && !_vm.isFindProcessStepPending)?_c('div',[_c('v-form',{ref:"form",model:{value:(_vm.validInput),callback:function ($$v) {_vm.validInput=$$v},expression:"validInput"}},[_c('v-card',{attrs:{"flat":""}},[(_vm.tokensUser && !_vm.externalAccountLinked)?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.$appConfig.externalIssues)+" account not linked. Link your account before creating external issues. The link Url is in your user menu at the top right.")]):_vm._e(),_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("New/Edit Issue")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"disabled":!(
                      _vm.$can('update', _vm.item, 'Title') ||
                      _vm.$can('create', 'external-issues')
                    ) ||
                    (_vm.item.ExternalId?.length > 0 &&
                      !_vm.updateFields.includes('Title')),"outlined":"","dense":"","hide-details":"true","placeholder":"Title","rules":[_vm.rules.required]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Title "),(_vm.syncFields.includes('Title'))?_c('v-icon',{staticClass:"mb-3 pr-1",attrs:{"x-small":""}},[_vm._v("mdi-connection")]):_vm._e()]},proxy:true}],null,false,4148156803),model:{value:(_vm.item.Title),callback:function ($$v) {_vm.$set(_vm.item, "Title", $$v)},expression:"item.Title"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"disabled":!(
                      _vm.$can('update', _vm.item, 'IssueTypeId') ||
                      _vm.$can('create', 'external-issues')
                    ) ||
                    (_vm.item.ExternalId?.length > 0 &&
                      !_vm.updateFields.includes('Type')),"items":_vm.filteredIssueTypes,"item-text":"Type","item-value":"id","label":"Type","outlined":"","rules":[_vm.rules.required],"dense":"","hide-details":"true"},on:{"change":function($event){_vm.item.StatusId = null}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Type "),(_vm.syncFields.includes('Type'))?_c('v-icon',{staticClass:"mb-3 pr-1",attrs:{"x-small":""}},[_vm._v("mdi-connection")]):_vm._e()]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.Icon)+" ")]),_vm._v(" "+_vm._s(item.Type)),(item.ExternalType)?_c('v-icon',{staticClass:"mb-3 ml-1",attrs:{"x-small":""}},[_vm._v("mdi-connection")]):_vm._e()]}},{key:"selection",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.Icon)+" ")]),_vm._v(" "+_vm._s(item.Type)),(item.ExternalType)?_c('v-icon',{staticClass:"mb-3 ml-1",attrs:{"x-small":""}},[_vm._v("mdi-connection")]):_vm._e()]}}],null,false,3007540086),model:{value:(_vm.item.IssueTypeId),callback:function ($$v) {_vm.$set(_vm.item, "IssueTypeId", $$v)},expression:"item.IssueTypeId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"text-body-1"},[_vm._v("Description")]),(_vm.syncFields.includes('Description'))?_c('v-icon',{staticClass:"mb-3",attrs:{"x-small":""}},[_vm._v("mdi-connection")]):_vm._e(),(_vm.show)?_c('RichEditor',{attrs:{"disabled":!(
                      _vm.$can('update', _vm.item, 'Content1') ||
                      _vm.$can('create', 'external-issues')
                    ) ||
                    (_vm.item.ExternalId?.length > 0 &&
                      !_vm.updateFields.includes('Description'))},model:{value:(_vm.item.Content1),callback:function ($$v) {_vm.$set(_vm.item, "Content1", $$v)},expression:"item.Content1"}}):_vm._e()],1),(false)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_vm._v(" Extra Description"),(_vm.syncFields.includes('Extra Description'))?_c('v-icon',{staticClass:"mb-3",attrs:{"x-small":""}},[_vm._v("mdi-connection")]):_vm._e(),_c('RichEditor',{attrs:{"disabled":!(
                      _vm.$can('update', _vm.item, 'Content2') ||
                      _vm.$can('create', 'external-issues')
                    ) ||
                    (_vm.item.ExternalId?.length > 0 &&
                      !_vm.updateFields.includes('Extra Description'))},model:{value:(_vm.item.Content2),callback:function ($$v) {_vm.$set(_vm.item, "Content2", $$v)},expression:"item.Content2"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"disabled":!(
                      _vm.$can('update', _vm.item, 'StatusId') ||
                      _vm.$can('create', 'external-issues')
                    ) ||
                    (_vm.item.ExternalId?.length > 0 &&
                      !_vm.updateFields.includes('Status')),"items":_vm.filteredStatus,"item-text":"Issue Status","item-value":"id","label":"Status","outlined":"","rules":[_vm.rules.required],"dense":"","hide-details":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Issue Status "),(_vm.syncFields.includes('Status'))?_c('v-icon',{staticClass:"mb-3 pr-1",attrs:{"x-small":""}},[_vm._v("mdi-connection")]):_vm._e()]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":item.Color}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s(item.Status)+" ")]}},{key:"selection",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":item.Color}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s(item.Status)+" ")]}}],null,false,2975601327),model:{value:(_vm.item['StatusId']),callback:function ($$v) {_vm.$set(_vm.item, 'StatusId', $$v)},expression:"item['StatusId']"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"disabled":!(
                      _vm.$can('update', _vm.item, 'AssignedId') ||
                      _vm.$can('create', 'external-issues')
                    ) ||
                    (_vm.item.ExternalId?.length > 0 &&
                      !_vm.updateFields.includes('Assignee')),"items":_vm.users,"item-text":"fullname","item-value":"id","label":"Assigned User","outlined":"","clearable":"","dense":"","hide-details":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Assigned User "),(_vm.syncFields.includes('Assignee'))?_c('v-icon',{staticClass:"mb-3 pr-1",attrs:{"x-small":""}},[_vm._v("mdi-connection")]):_vm._e()]},proxy:true},{key:"item",fn:function({ item }){return [_c('UserAvatar',{attrs:{"Name":item.fullname,"Image":item.gravatarUrl,"Color":item.color}}),_vm._v(" "+_vm._s(item.fullname))]}},{key:"selection",fn:function({ item }){return [_c('UserAvatar',{attrs:{"Name":item.fullname,"Image":item.gravatarUrl,"Color":item.color}}),_vm._v(" "+_vm._s(item.fullname))]}}],null,false,232619430),model:{value:(_vm.item['AssignedId']),callback:function ($$v) {_vm.$set(_vm.item, 'AssignedId', $$v)},expression:"item['AssignedId']"}},[_vm._v(" >")])],1),_c('v-col',{attrs:{"cols":"12","md":"4","align":"right"}},[_c('v-checkbox',{staticClass:"d-inline-flex mr-4",attrs:{"disabled":!(
                      _vm.$can('update', _vm.item, 'Blocking') ||
                      _vm.$can('create', 'external-issues')
                    ) ||
                    (_vm.item.ExternalId?.length > 0 &&
                      !_vm.updateFields.includes('Severity')),"label":`Blocking`,"dense":"","hide-details":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Blocking "),(_vm.syncFields.includes('Severity'))?_c('v-icon',{staticClass:"mb-3 pr-1",attrs:{"x-small":""}},[_vm._v("mdi-connection")]):_vm._e()]},proxy:true}],null,false,3522839321),model:{value:(_vm.item.Blocking),callback:function ($$v) {_vm.$set(_vm.item, "Blocking", $$v)},expression:"item.Blocking"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Due Date","prepend-icon":"mdi-calendar","readonly":"","dense":"","outlined":"","hide-details":"true","disabled":!(
                          _vm.$can('update', _vm.item, 'DueDate') ||
                          _vm.$can('create', 'external-issues')
                        ) ||
                        (_vm.item.ExternalId?.length > 0 &&
                          !_vm.updateFields.includes('Due Date'))},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Due Date "),(_vm.syncFields.includes('Due Date'))?_c('v-icon',{staticClass:"mb-3 pr-1",attrs:{"x-small":""}},[_vm._v("mdi-connection")]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.item.DueDate),callback:function ($$v) {_vm.$set(_vm.item, "DueDate", $$v)},expression:"item.DueDate"}},'v-text-field',attrs,false),on))]}}],null,false,995480496),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDate = false}},model:{value:(_vm.item.DueDate),callback:function ($$v) {_vm.$set(_vm.item, "DueDate", $$v)},expression:"item.DueDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"disabled":!(
                      _vm.$can('update', _vm.item, 'DueStatusId') ||
                      _vm.$can('create', 'external-issues') ||
                      (_vm.item.ExternalId?.length > 0 &&
                        !_vm.updateFields.includes('Due Status'))
                    ),"items":_vm.status,"item-text":"Status","item-value":"id","label":"Due Status","outlined":"","dense":"","hide-details":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Due Status "),(_vm.syncFields.includes('Due Status'))?_c('v-icon',{staticClass:"mb-3 pr-1",attrs:{"x-small":""}},[_vm._v("mdi-connection")]):_vm._e()]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":item.Color}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s(item.Status)+" ")]}},{key:"selection",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":item.Color}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s(item.Status)+" ")]}}],null,false,1739691798),model:{value:(_vm.item['DueStatusId']),callback:function ($$v) {_vm.$set(_vm.item, 'DueStatusId', $$v)},expression:"item['DueStatusId']"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2 mr-2",attrs:{"icon":""},on:{"click":function($event){_vm.showProcessLinks = !_vm.showProcessLinks}}},[_c('v-icon',[_vm._v(_vm._s(_vm.showProcessLinks ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1)],1),_c('v-expand-transition',[(_vm.showProcessLinks)?_c('v-card',{staticClass:"transition-fast-in-fast-out v-card--reveal ma-2",staticStyle:{"height":"100%"},attrs:{"outlined":""}},[_c('v-card-subtitle',{staticClass:"ml-1 pl-1 text-subtitle-1"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-link-variant")]),_vm._v("Links")],1),_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"disabled":!(
                          _vm.$can('update', _vm.item, 'ProcessId') ||
                          _vm.$can('create', 'external-issues')
                        ),"items":_vm.processList,"item-text":"fullname","item-value":"id","label":"Process","outlined":"","clearable":"","dense":"","hide-details":"true"},on:{"change":function($event){_vm.item.VariantId = null;
                        _vm.item.ProcessStepId = null;
                        _vm.item.testScenarioRunStepId = null;}},model:{value:(_vm.item['ProcessId']),callback:function ($$v) {_vm.$set(_vm.item, 'ProcessId', $$v)},expression:"item['ProcessId']"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"disabled":!(
                          _vm.$can('update', _vm.item, 'VariantId') ||
                          _vm.$can('create', 'external-issues')
                        ),"items":_vm.variantList,"item-text":"fullname","item-value":"id","label":"Variant","outlined":"","clearable":"","dense":"","hide-details":"true"},on:{"change":function($event){_vm.item.ProcessStepId = null;
                        _vm.item.testScenarioRunStepId = null;}},model:{value:(_vm.item['VariantId']),callback:function ($$v) {_vm.$set(_vm.item, 'VariantId', $$v)},expression:"item['VariantId']"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"disabled":!(
                          _vm.$can('update', _vm.item, 'ProcessStepId') ||
                          _vm.$can('create', 'external-issues')
                        ),"items":_vm.processStepList,"item-text":"fullname","item-value":"id","label":"Process Step","outlined":"","clearable":"","dense":"","hide-details":"true"},on:{"change":function($event){_vm.item.testScenarioRunStepId = null}},model:{value:(_vm.item['ProcessStepId']),callback:function ($$v) {_vm.$set(_vm.item, 'ProcessStepId', $$v)},expression:"item['ProcessStepId']"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"disabled":!(
                          _vm.$can('update', _vm.item, 'TestScenarioRunStepId') ||
                          _vm.$can('create', 'external-issues')
                        ),"items":_vm.fullTestScenarioRunSteps,"item-text":"fullName","item-value":"id","label":"Test Scenario Step","outlined":"","clearable":"","dense":"","hide-details":"true"},model:{value:(_vm.item['TestScenarioRunStepId']),callback:function ($$v) {_vm.$set(_vm.item, 'TestScenarioRunStepId', $$v)},expression:"item['TestScenarioRunStepId']"}})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"disabled":!(
                          _vm.$can('update', _vm.item, 'ExternalId') ||
                          _vm.$can('create', 'external-issues')
                        ),"outlined":"","dense":"","hide-details":"true","label":"External Id","placeholder":"External Id"},model:{value:(_vm.item.ExternalId),callback:function ($$v) {_vm.$set(_vm.item, "ExternalId", $$v)},expression:"item.ExternalId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"disabled":!(
                          _vm.$can('update', _vm.item, 'Url') ||
                          _vm.$can('create', 'external-issues')
                        ),"outlined":"","dense":"","hide-details":"true","label":"Url","placeholder":"Url"},model:{value:(_vm.item.Url),callback:function ($$v) {_vm.$set(_vm.item, "Url", $$v)},expression:"item.Url"}})],1)],1)],1)],1):_vm._e()],1),_c('v-card-actions',[(
                _vm.$can('delete', 'external-issues') &&
                _vm.item.id &&
                (!_vm.item.IssueType?.ExternalType ||
                  (_vm.item.IssueType?.ExternalType && !_vm.item.ExternalId))
              )?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.showRemoveMessage = true}}},[_vm._v("Remove")]):_vm._e(),(_vm.showRemoveMessage)?_c('div',{staticClass:"ml-5"},[_vm._v(" Are you sure? "),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error","loading":_vm.saving},on:{"click":function($event){return _vm.$emit('remove')}}},[_vm._v("Yes")])],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":!_vm.validInput,"loading":_vm.saving,"color":"primary"},on:{"click":function($event){return _vm.$emit('save')}}},[_vm._v(" Save ")])],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }