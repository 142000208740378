<template>
  <v-container v-if="item">
    <h1>
      Test Scenario Run Builder <span v-if="item.id"> ({{ item.id }})</span>
    </h1>
    <div class="my-3">
      <DrawIO
        v-if="testScenarioHeader?.id"
        :testScenarioHeaderId="testScenarioHeader.id"
      />
    </div>
    <v-form ref="form" v-model="validInput">
      <v-card flat class="pb-2">
        <v-card-title>
          <span class="headline">Create New Test Scenario Suite</span>
        </v-card-title>

        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="item.Name"
                label="Name"
                outlined
                dense
                :rules="[rules.required]"
                @change="item.id ? saveRecord() : (change = true)"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="always-on-top">
              <v-select
                v-model="item.TestScenarioTypeId"
                :items="testScenarioType"
                item-text="Name"
                item-value="id"
                label="Type"
                outlined
                dense
                :rules="[rules.required]"
                @change="item.id ? saveRecord() : (change = true)"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="2" class="always-on-top">
              <v-checkbox
                v-model="item.ReadyToTest"
                label="Ready to Test"
                outlined
                dense
                @change="item.id ? saveRecord() : (change = true)"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" md="1" class="always-on-top" align="right">
              <v-icon color="primary" @click="addTC = true"
                >mdi-briefcase-plus</v-icon
              ></v-col
            >
            <v-col cols="12" md="12">
              <RichEditor
                v-model="item.Description"
                label="Description"
                placeholder="Description"
                height="200px"
                @change="item.id ? saveRecord() : (change = true)"
              ></RichEditor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="!testScenarioHeader.id">
          <v-spacer></v-spacer>
          <v-btn outlined @click="$router.push('/TestScenariosOverview')"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :loading="saving"
            :disabled="!validInput"
            @click="saveRecord"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card flat v-if="testScenarioHeader.id">
        <v-card-text>
          <TestSummary :testScenarioHeader="testScenarioHeader" />
        </v-card-text>

        <v-card-title
          ><span class="headline">Test Scenario's</span>
          <v-icon
            v-if="$can('update', 'test-scenario-line', 'Order')"
            class="ml-5"
            @click="showReorder = true"
          >
            mdi-order-numeric-ascending
          </v-icon></v-card-title
        ><v-card-text>
          <TestScenarioLine
            :testScenarioHeader="testScenarioHeader"
            :key="refreshKey"
        /></v-card-text>
        <v-card-actions>
          <v-btn
            v-if="$can('delete', 'test-scenario-header')"
            outlined
            @click="showRemoveMessage = true"
            >Remove</v-btn
          >
          <div v-if="showRemoveMessage" class="ml-5">
            Are you sure? Test Scenario Suite and all related steps will be
            removed.
            <v-btn class="ml-2" :loading="saving" @click="remove" color="error"
              >Yes</v-btn
            >
          </div>
          <v-spacer></v-spacer>
          <div v-if="change">
            <v-btn outlined @click="$router.push('/TestScenariosOverview')"
              >Cancel</v-btn
            >

            <v-btn
              color="primary"
              :loading="saving"
              :disabled="!validInput"
              @click="saveRecord"
            >
              Save
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              color="primary"
              :disabled="!validInput"
              @click="$router.push('/TestScenariosOverview')"
            >
              Close
            </v-btn>
          </div>
        </v-card-actions></v-card
      >
    </v-form>

    <Modal
      v-if="showReorder"
      title="Reorder Test Scenario Lines"
      v-model="showReorder"
      :hide-cancel="true"
      :hide-confirm="true"
      width="900px"
    >
      <ReOrder
        v-if="showReorder"
        v-model="showReorder"
        :testScenarioHeader="testScenarioHeader"
    /></Modal>
    <Modal
      v-if="addTC"
      title="Add Test Case"
      v-model="addTC"
      :hide-cancel="true"
      @confirm="addTC = false"
      width="900px"
    >
      <TestScenarioHeaderTestCase :testScenarioHeader="testScenarioHeader" />
    </Modal>
  </v-container>
</template>

<script>
import RichEditor from '@/components/general/RichEditor.vue';
import DrawIO from '@/components/Drawing/drawio';
import TestScenarioLine from '../TestScenarioHeader/TestScenarioLine.vue';
import TestSummary from './TestSummaries.vue';
import TestScenarioHeaderTestCase from './TestScenarioHeaderTestCase.vue';
import ReOrder from './ReOrder.vue';
import { makeFindMixin } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
import Modal from '@/components/general/Modal.vue';
export default {
  components: {
    RichEditor,
    DrawIO,
    TestScenarioLine,
    TestSummary,
    TestScenarioHeaderTestCase,
    ReOrder,
    Modal,
  },
  props: {
    testScenarioHeader: {
      type: Object,
      required: true,
    },
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-type',
    }),
    makeFindMixin({
      service: 'export-queries',
    }),
  ],
  watch: {
    '$route.params.id': {
      handler: function () {
        if (this.id == 0) {
          this.$emit('enableAdd', false);
        } else {
          this.$emit('enableAdd', true);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      refreshKey: 0,
      item: null,
      validInput: true,
      showAdd: false,
      showRemoveMessage: false,
      change: false,
      saving: false,
      showReorder: false,
      addTC: false,
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
    };
  },
  methods: {
    async saveRecord() {
      this.saving = true;
      try {
        await this.item.save();
        handleSaveResponse(this.item.Name, 'Test Scenario Suite', 'created');
        this.change = false;
        this.saving = false;
        if (this.id == 0) {
          this.$router.push({
            name: 'testscenariobuilder',
            params: { id: this.item.id },
          });
        }
      } catch (error) {
        handleErrorResponse(error);
      }
      this.saving = false;
    },
    async remove() {
      this.saving = true;
      try {
        await this.item.remove();
        handleSaveResponse(this.item.Name, 'Test Scenario Suite', 'removed');
        this.saving = false;
        this.$router.push('/TestScenariosOverview');
      } catch (error) {
        handleErrorResponse(error);
      }
      this.saving = false;
    },
  },
  computed: {
    testScenarioTypeParams() {
      return {};
    },
    exportQueriesParams() {
      return { query: { type: 'headerFields' } };
    },

    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.item = this.testScenarioHeader.clone();
  },
};
</script>

<style lang="scss" scoped>
.always-on-top {
  z-index: 11; /* A high value to ensure it stays on top */
}
</style>
