<template>
  <div>
    <v-dialog :value="true" persistent max-width="95vw">
      <v-card class="pl-5 pr-5 pb-5">
        <v-card-title class="text-h5 mb-0 pb-0">
          Execute Test Run: {{ testScenarioRun.id }} - {{ testScenarioRun.Name
          }}<v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row
            ><v-col v-if="testScenarioRun.Description" class="text-pre-wrap"
              ><span v-html="testScenarioRun.Description"></span> </v-col
          ></v-row>
        </v-card-text>
        <v-card-subtitle class="mb-0 pb-0">
          <div class="text-h5">
            {{ testScenarioRunDetail.Order }} - {{ testScenarioRunDetail.Name }}
            <v-tooltip
              bottom
              v-if="
                ($feature('cloudinaryChartIntegration') && processUrl) ||
                ($feature('lucidChartIntegration') && lucidUrl) ||
                ($feature('drawIOChartIntegration') && drawingUrl)
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2 mb-1"
                  @click="
                    openWindow(
                      $feature('drawIOChartIntegration') && drawingUrl
                        ? drawingUrl
                        : $feature('lucidChartIntegration') && lucidUrl
                        ? lucidUrl
                        : processUrl
                    )
                  "
                >
                  mdi-image-search-outline
                </v-icon>
              </template>
              <span>Process Flows</span>
            </v-tooltip>
          </div>

          <router-link
            :to="{
              name: 'processDetail',
              params: {
                id: testScenarioRunDetail.process.ProcessId,
                number: testScenarioRunDetail.process.ProcessNumber,
              },
            }"
            >{{ $appConfig.customer.prefix
            }}{{
              testScenarioRunDetail.process.ProcessNumber.toString().padStart(
                4,
                '0'
              )
            }}
            - {{ testScenarioRunDetail.process.ProcessName }}</router-link
          >

          <v-icon small>mdi-chevron-right</v-icon>
          <router-link
            :to="{
              name: 'processStep',
              params: { id: testScenarioRunDetail.process.VariantId },
            }"
            >{{ testScenarioRunDetail.process.VariantNumber }} -
            {{ testScenarioRunDetail.process.VariantName }}</router-link
          >
        </v-card-subtitle>
        <v-card-text>
          <v-row
            ><v-col v-if="testScenarioRunDetail.Description" class="mb-0 pb-0">
              <span v-html="testScenarioRunDetail.Description"></span> </v-col
          ></v-row>

          <v-row
            ><v-col>
              <ApplicationList
                v-if="this.testScenarioRunDetail"
                :variant="{
                  id: this.testScenarioRunDetail?.process?.VariantId,
                }"
                :selectedErpApplicationIds="
                  this.testScenarioRunDetail?.test_scenario_line
                    .ErpApplicationIds
                "
              /> </v-col
          ></v-row>

          <v-expansion-panels flat accordion>
            <v-expansion-panel
              v-if="
                testScenarioRunDetail.process.ProcessInput ||
                testScenarioRunDetail.process.ProcessOutput
              "
            >
              <v-expansion-panel-header hide-actions>
                <template v-slot:default="{ open }">
                  <div>
                    Process Description
                    <v-icon>
                      <template v-if="open">mdi-chevron-up</template>
                      <template v-else>mdi-chevron-down</template>
                    </v-icon>
                  </div></template
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text
                  class="my-0 py-0"
                  v-html="testScenarioRunDetail.process.ProcessInput"
                ></v-card-text>
                <v-card-text
                  class="my-0 py-0"
                  v-html="testScenarioRunDetail.process.ProcessOutput"
                ></v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              v-if="
                testScenarioRunDetail.process.VariantInput ||
                testScenarioRunDetail.process.VariantOutput
              "
            >
              <v-expansion-panel-header hide-actions>
                <template v-slot:default="{ open }">
                  <div>
                    Variant Description
                    <v-icon>
                      <template v-if="open">mdi-chevron-up</template>
                      <template v-else>mdi-chevron-down</template>
                    </v-icon>
                  </div></template
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text
                  class="my-0 py-0"
                  v-html="testScenarioRunDetail.process.VariantInput"
                ></v-card-text>
                <v-card-text
                  class="my-0 py-0"
                  v-html="testScenarioRunDetail.process.VariantOutput"
                ></v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="testDetailLines?.length > 0">
              <v-expansion-panel-header hide-actions>
                <template v-slot:default="{ open }">
                  <div>
                    Test History ({{
                      testDetailLines.filter((f) => f.AllDone == true)?.length
                    }})
                    <v-icon>
                      <template v-if="open">mdi-chevron-up</template>
                      <template v-else>mdi-chevron-down</template>
                    </v-icon>
                  </div></template
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  dense
                  :headers="headers2"
                  :items="testDetailLines"
                >
                  <template v-slot:[`item.Output`]="{ item }"
                    ><ul>
                      <li
                        v-for="(
                          output, index
                        ) of exportQueriesLatestQuery?.response.filter(
                          (f) => f.TestScenarioRunDetailId == item.id
                        )"
                        :key="index"
                      >
                        {{ output.OutputName }}: {{ output.InputName }}
                      </li>
                    </ul></template
                  >

                  <template v-slot:[`item.AllDone`]="{ item }"
                    ><v-icon color="green" v-if="item.AllDone"
                      >mdi-check-bold</v-icon
                    >
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div v-if="testScenarioRunDetail.Detailed">
            <v-data-table
              v-if="testScenarioRunSteps"
              :headers="headers"
              :items="testScenarioRunSteps"
              :sort-by="['Key', 'Number']"
              :sort-desc="[false, false]"
              disable-pagination
              hide-default-footer
              show-expand
              class="text-pre-wrap"
              :loading="isFindTestScenarioRunStepsPending"
              loading-text="Loading... Please wait"
              :expanded.sync="expandedRows"
            >
              <template v-slot:[`item.Number`]="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:[`item.color`]="{ item }">
                <span
                  v-for="color in group.find((f) => f.id == item.GroupId)
                    ?.allGroups"
                  :key="color.id"
                  ><span v-if="color.Loop">
                    <v-tooltip bottom open-delay="500">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="circle"
                          :style="{
                            background: color.Color,
                            color: applyDark(color.Color) ? 'white' : 'black',
                          }"
                          >{{
                            item.Groups?.find((f) => f.id == color.id)
                              ?.iteration + 1
                          }}</span
                        ></template
                      >{{ color.Name }}</v-tooltip
                    ></span
                  ><span v-else>
                    <v-tooltip bottom open-delay="500">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="circle"
                          :style="{
                            background: color.Color,
                            color: applyDark(color.Color) ? 'white' : 'black',
                          }"
                        >
                          &nbsp;</span
                        ></template
                      >{{ color.Name }}</v-tooltip
                    >
                  </span>
                </span>
              </template>
              <template v-slot:[`item.Fields`]="i = { item }"
                ><div>
                  <v-list
                    dense
                    class="ma-0"
                    v-if="
                      testScenarioRunFieldValues.find(
                        (f) => f.TestScenarioRunStepsId == i.item.id
                      )
                    "
                  >
                    <v-list-item
                      v-for="field in listField(i.item.id)"
                      :key="field.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ field }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-for="value in listFieldValues(i.item.id, field)"
                          :key="value.id"
                        >
                          {{ value.Code }}
                          <span v-if="value.Name"
                            >({{ value.Name }})</span
                          ></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div></template
              >
              <template v-slot:[`item.Output`]="{ item }">
                <div
                  v-for="output of item.test_scenario_line_step
                    ?.test_scenario_outputs"
                  :key="output.id"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        min-width="100px"
                        :loading="loadInputs"
                        v-if="output.Name?.length > 0"
                        v-model="InputSteps[output.id]"
                        :label="output.Name"
                        outlined
                        dense
                        hide-details
                        style="
                          margin-top: 10px;
                          margin-bottom: 10px;
                          min-width: 150px;
                        "
                        :rules="[rules.required]"
                        @blur="saveInputStep(output, item)"
                        ><template v-slot:label>
                          <span class="small">{{ output.Name }}</span>
                        </template>
                      </v-text-field></template
                    ><span>{{ output.Name }}</span></v-tooltip
                  >
                </div>
              </template>
              <template v-slot:[`item.TestRemark`]="{ item }">
                <!-- {{ item.test_scenario_line_step }} -->
                <span v-html="item.TestRemark" />
                <br />
                <div v-if="item.inputs.length > 0">
                  <div v-for="input of item.inputs" :key="input.InputId">
                    <v-icon>mdi-menu-right</v-icon>
                    <span style="font-weight: 500"
                      >{{ input.OutputName }}: {{ input.InputValue }}</span
                    >
                    <v-icon
                      v-if="input.InputValue"
                      small
                      class="ml-2"
                      @click="currentInputItem = input"
                      >mdi-qrcode</v-icon
                    >
                  </div>
                </div>
              </template>
              <template v-slot:[`item.TestResult`]="{ item }"
                ><div>
                  <v-icon
                    @click="validateTest(item)"
                    v-if="item.Done && item.blockedBugs == 0"
                    color="green"
                    small
                    class="mr-1"
                    >mdi-check-bold</v-icon
                  >
                  <v-icon
                    v-if="item.blockedBugs > 0"
                    color="red"
                    small
                    class="mr-1"
                    >mdi-close-thick</v-icon
                  ><v-icon
                    @click="validateTest(item)"
                    v-if="!item.Done && item.blockedBugs == 0"
                    color="primary"
                    small
                    class="mr-1"
                    >mdi-checkbox-blank-outline
                  </v-icon>
                </div>
              </template>
              <template v-slot:[`item.Issue`]="{ item }">
                <v-icon
                  class="ml-2"
                  small
                  @click="clickAddIssue(item)"
                  :color="
                    item.blockedBugs > 0
                      ? 'red'
                      : item.openBugs > 0
                      ? 'orange'
                      : item.issueStatus?.length > 0
                      ? 'green'
                      : 'primary'
                  "
                >
                  mdi-login-variant
                </v-icon>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td
                  :colspan="headers.length"
                  v-if="item.process_step?.Description"
                >
                  <v-row
                    ><v-col class="mb-0 pb-0"
                      ><span
                        class="scaleImageMax ma-3"
                        v-html="item.process_step.Description"
                      ></span></v-col></v-row
                  ><v-row
                    ><v-col align="right" class="mt-0 pt-0">
                      <v-btn icon @click="toggle(item.id)">
                        <v-icon small>{{ 'mdi-swap-horizontal' }}</v-icon>
                      </v-btn>
                      <Updated
                        v-if="show[item.id]"
                        class="mr-5 my-2"
                        :updateDate="item.updatedAt"
                        :userId="item.ModifiedById"
                        table="process_step"
                        :id="item.id" /></v-col
                  ></v-row>
                </td>
              </template>
            </v-data-table>
          </div>
          <div v-if="!testScenarioRunDetail.Detailed">Line Level Test</div>
          <span v-if="this.testScenarioRunDetail.ApplicationMatch">
            The next test uses the same application. Keep the current screen
            open.
          </span>
          <div
            class="mt-5"
            v-if="
              testScenarioRunDetail?.test_scenario_line?.test_scenario_outputs
                ?.length > 0
            "
          >
            <h4 class="my-0 py-0">Test Outputs</h4>
            <v-row>
              <v-col
                rows="12"
                md="4"
                v-for="output of testScenarioRunDetail.test_scenario_line
                  .test_scenario_outputs"
                :key="output.id"
              >
                <v-text-field
                  loading="loadInputs"
                  v-model="InputLine[output.id]"
                  :label="output.Name"
                  outlined
                  dense
                  hide-details
                  style="margin-top: 10px; margin-bottom: 10px"
                  :rules="[rules.required]"
                  @blur="saveInputLine(output, testScenarioRunDetail)"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <div v-if="testScenarioRunDetail?.inputs?.length > 0">
            <h4 class="my-0 py-0">Test Inputs</h4>
            <v-row>
              <v-col rows="12" md="4">
                <div
                  v-for="input of testScenarioRunDetail.inputs"
                  :key="input.InputId"
                >
                  <v-icon>mdi-menu-right</v-icon>
                  <span style="font-weight: 500"
                    >{{ input.OutputName }}: {{ input.InputValue }}</span
                  >
                </div>
              </v-col></v-row
            >
          </div>
        </v-card-text>
        <v-card-actions class="mt-5">
          <v-btn
            :loading="validating"
            @click="validateAll(true)"
            :disabled="
              !testScenarioRunSteps.filter((f) => f.Done == false)?.length > 0
            "
            >Validate All</v-btn
          >
          <!-- <v-btn
            :loading="validating"
            @click="validateAll(true)"
            :disabled="
              !testScenarioRunSteps.filter((f) => f.Done == false)?.length > 0
            "
            >Validate All and Close</v-btn
          > -->
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="closing || validating"
            @click="close()"
          >
            Close
          </v-btn>
        </v-card-actions>
        <v-dialog
          v-if="issueDialog"
          v-model="issueDialog"
          hide-overlay
          width="90vw"
          height="auto"
          ><v-card height="90vh" class="pa-5">
            <IssueList
              v-if="issueDialog"
              v-model="issueDialog"
              :testScenarioRunStep="currentStep"
            />
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
    <Modal
      :title="`${currentInputItem.OutputName} - ${currentInputItem.InputValue}`"
      v-if="currentInputItem"
      width="600px"
      :hideCancel="true"
      @confirm="currentInputItem = null"
    >
      <ShowQrCode :value="currentInputItem.InputValue" />
    </Modal>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import Updated from '@/components/general/Updated.vue';
import IssueList from '@/components/Issues/IssueList.vue';
import { handleErrorResponse } from '@/utils/MessageHandler';
import feathersClient from '@/feathers-client';
import { applyDark } from '@/utils/Utilities';
import {
  getDocumentEmbed,
  getDocumentEmbedToken,
} from '@/utils/LucidChartFunctions';
import ShowQrCode from '@/components/general/ShowQrCode.vue';
import Modal from '@/components/general/Modal.vue';
import ApplicationList from '@/components/Variant/ApplicationList.vue';

export default {
  props: {
    testScenarioRun: {
      type: Object,
      required: true,
    },
    testScenarioRunDetail: {
      type: Object,
      required: true,
    },

    ro: { type: Boolean, default: false, required: false },
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-run-steps',
      watch: 'testScenarioRunDetail.id',
    }),
    makeFindMixin({
      service: 'test-scenario-run-field-values',
      watch: 'testScenarioRunDetail.id',
    }),
    makeFindMixin({
      service: 'group',
    }),
    makeFindMixin({
      service: 'export-queries',
    }),
    makeFindMixin({
      service: 'test-scenario-run-detail',
      name: 'testDetailLines',
      watch: 'testScenarioRunDetail.id',
    }),
  ],
  watch: {
    isFindTestScenarioRunStepsPending: {
      handler: async function () {
        if (!this.isFindTestScenarioRunStepsPending) {
          this.loadInputs = true;
          const { TestScenarioRunInput } = this.$FeathersVuex.api;
          const uniqueRunStepIds = this.testScenarioRunSteps.map(
            (step) => step.id
          );

          const Inputs = await TestScenarioRunInput.find({
            query: { TestScenarioRunStepsId: { $in: uniqueRunStepIds } },
          });
          if (Inputs.total > 0) {
            for (const input of Inputs.data) {
              this.InputSteps[input.TestScenarioOutputId] = input.Name;
            }
          }
          this.loadInputs = false;
        }
      },
      immediate: true,
    },
    'testScenarioRunDetail.id': {
      handler: async function () {
        if (this.testScenarioRunDetail?.id) {
          this.loadInputs = true;
          const { TestScenarioRunInput } = this.$FeathersVuex.api;

          const Inputs = await TestScenarioRunInput.find({
            query: { TestScenarioRunDetailId: this.testScenarioRunDetail.id },
          });
          if (Inputs.total > 0) {
            for (const input of Inputs.data) {
              this.InputLine[input.TestScenarioOutputId] = input.Name;
            }
          }
          this.loadInputs = false;
        }
      },
      immediate: true,
    },
  },

  components: { Updated, IssueList, ShowQrCode, Modal, ApplicationList },
  data() {
    return {
      show: [],
      loading: false,
      currentItem: null,
      embedToken: null,
      currentStep: null,
      TestOutput: null,
      StepValidated: false,
      issueDialog: false,
      closing: false,
      validating: false,
      isTableVisible: false,
      InputSteps: [],
      InputLine: [],
      loadInputs: false,
      currentInputItem: null,
      expandedRows: [],
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      headers: [
        {
          text: '',
          value: 'color',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Number',
          value: 'Number',
          sortable: false,
          groupable: false,
        },

        {
          text: 'Step',
          value: 'Name',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Fields',
          value: 'Fields',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Output',
          value: 'Output',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Test Remark',
          value: 'TestRemark',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Test Result',
          value: 'TestResult',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: 'Issue',
          value: 'Issue',
          sortable: false,
          groupable: false,
          align: 'center',
        },

        { text: '', value: 'data-table-expand' },
      ],
      headers2: [
        { text: 'Order', value: 'Order' },
        { text: 'Name', value: 'Name' },
        { text: 'Output', value: 'Output' },
        { text: 'Done', value: 'AllDone' },
      ],
    };
  },
  methods: {
    applyDark,
    toggleAllRows() {
      this.expandedRows = this.allExpanded
        ? []
        : this.testScenarioRunSteps.filter((item) => item.id);
    },
    async saveInputStep(output, step) {
      if (
        this.InputSteps[output.id] == null ||
        this.InputSteps[output.id] === ''
      ) {
        handleErrorResponse({ message: 'Input is required' });
        return;
      }
      const { TestScenarioRunInput } = this.$FeathersVuex.api;
      const input = await TestScenarioRunInput.find({
        query: {
          TestScenarioRunStepsId: step.id,
          TestScenarioOutputId: output.id,
        },
      });
      if (input.total > 0) {
        input.data[0].Name = this.InputSteps[output.id];
        try {
          await input.data[0].save();
        } catch (error) {
          handleErrorResponse(error);
        }
      } else {
        let newInput = new TestScenarioRunInput();
        newInput.Name = this.InputSteps[output.id];
        newInput.TestScenarioRunStepsId = step.id;
        newInput.TestScenarioOutputId = output.id;
        try {
          await newInput.save();
        } catch (error) {
          handleErrorResponse(error);
        }
      }
    },
    async saveInputLine(output, line) {
      if (
        this.InputLine[output.id] == null ||
        this.InputLine[output.id] === ''
      ) {
        handleErrorResponse({ message: 'Input is required' });
        return;
      }
      const { TestScenarioRunInput } = this.$FeathersVuex.api;
      const input = await TestScenarioRunInput.find({
        query: {
          TestScenarioRunDetailId: line.id,
          TestScenarioOutputId: output.id,
        },
      });
      if (input.total > 0) {
        input.data[0].Name = this.InputLine[output.id];
        try {
          await input.data[0].save();
        } catch (error) {
          handleErrorResponse(error);
        }
      } else {
        let newInput = new TestScenarioRunInput();
        newInput.Name = this.InputLine[output.id];
        newInput.TestScenarioRunDetailId = line.id;
        newInput.TestScenarioOutputId = output.id;
        try {
          await newInput.save();
        } catch (error) {
          handleErrorResponse(error);
        }
      }
    },
    toggleTable() {
      this.isTableVisible = !this.isTableVisible;
    },
    async close() {
      // If all steps are validated check if all inputs are filled in
      let allValidate = false;
      for (const step of this.testScenarioRunSteps) {
        if (step.Done) {
          allValidate = true;
        } else {
          allValidate = false;
          break;
        }
      }

      if (allValidate) {
        const outputIds =
          this.testScenarioRunDetail?.test_scenario_line?.test_scenario_outputs.map(
            (output) => output.id
          );

        for (const output of outputIds) {
          const { TestScenarioRunInput } = this.$FeathersVuex.api;
          const input = await TestScenarioRunInput.find({
            query: {
              TestScenarioRunDetailId: this.testScenarioRunDetail.id,
              TestScenarioOutputId: output,
            },
          });
          if (input.total === 0 || !input.data[0]?.Name) {
            handleErrorResponse({
              message: `Input is required`,
            });
            return;
          }
        }
      }

      this.closing = true;
      await this.refresh();

      this.$emit('cancel');
      this.ro ? this.$router.go(-1) : '';
      this.closing = false;
    },
    clickAddIssue(item) {
      this.issueDialog = true;
      this.currentStep = item;
    },
    async validateAll(close) {
      this.validating = true;

      // Check if al inputs are filled in (only when using detailed testing)
      if (this.testScenarioRunDetail.Detailed) {
        const errors = [];

        for (const step of this.testScenarioRunSteps) {
          const outputIds =
            step.test_scenario_line_step?.test_scenario_outputs.map(
              (output) => output.id
            );
          for (const output of outputIds) {
            const { TestScenarioRunInput } = this.$FeathersVuex.api;
            const input = await TestScenarioRunInput.find({
              query: {
                TestScenarioRunStepsId: step.id,
                TestScenarioOutputId: output,
              },
            });
            if (input.total === 0 || !input.data[0]?.Name) {
              errors.push(step.process_step.Name);
              // handleErrorResponse({
              //   message: `All inputs are required'`,
              // });
              // this.validating = false;
              // return;
            }
          }
        }

        if (errors.length > 0) {
          const uniqueErrors = [...new Set(errors)];
          handleErrorResponse({
            message: `Input is required on steps: '${uniqueErrors.join(', ')}'`,
          });
          this.validating = false;
          return;
        }
      }

      try {
        await feathersClient.service('test-scenario-run-steps').patch(
          null,
          { Done: true },
          {
            query: { TestScenarioRunDetailId: this.testScenarioRunDetail.id },
          }
        );
        this.StepValidated = true;
      } catch (error) {
        console.log(error);
      }

      await this.refresh();

      // for (const step of this.testScenarioRunSteps.sort(
      //   (a, b) => a.Number - b.Number
      // )) {
      //   await this.validateTest(step);
      // }
      this.validating = false;
      if (close) {
        this.$emit('cancel');
      }
    },
    toggle(i) {
      if (this.show[i] == undefined) {
        this.show[i] = true;
        this.show = [...this.show];
      } else {
        this.show[i] = !this.show[i];
        this.show = [...this.show];
      }
    },
    async saveOutputReference() {
      // this.testScenarioRunDetail.TestOutput = this.TestOutput;
      try {
        await this.testScenarioRunDetail.save();
      } catch (error) {
        handleErrorResponse(error);
      }

      this.StepValidated = true;
      await this.refresh();
    },
    async validateTest(item) {
      const outputIds = item.test_scenario_line_step?.test_scenario_outputs.map(
        (output) => output.id
      );

      for (const output of outputIds) {
        const { TestScenarioRunInput } = this.$FeathersVuex.api;
        const input = await TestScenarioRunInput.find({
          query: {
            TestScenarioRunStepsId: item.id,
            TestScenarioOutputId: output,
          },
        });
        if (input.total === 0 || !input.data[0]?.Name) {
          handleErrorResponse({
            message: `Input is required on step '${item.process_step.Name}''`,
          });
          return;
        }
      }

      item.Done = !item.Done;
      try {
        await item.save();
      } catch (error) {
        handleErrorResponse(error);
      }

      this.StepValidated = true;
    },
    async refresh() {
      const { TestScenarioRun, TestScenarioRunDetail } = this.$FeathersVuex.api;

      await TestScenarioRunDetail.find({
        query: { TestScenarioRunId: this.testScenarioRun.id },
      });
      await TestScenarioRun.find({
        query: { id: this.testScenarioRun.id },
      });
    },
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    async LucidEmbed() {
      if (this.testScenarioRunDetail?.process?.ExternalDocumentId) {
        if (!this.testScenarioRunDetail?.process?.EmbedId) {
          await getDocumentEmbed({
            id: this.testScenarioRunDetail?.process?.ProcessId,
            ExternalDocumentId:
              this.testScenarioRunDetail?.process?.ExternalDocumentId,
          });
          // console.log('No Embed');
        }
        this.embedToken = await getDocumentEmbedToken({
          id: this.testScenarioRunDetail?.process?.ProcessId,
          ExternalDocumentId:
            this.testScenarioRunDetail?.process?.ExternalDocumentId,
        });
        // console.log('Embed', this.embedToken);
      } else {
        // console.log('No document Linked');
      }
    },
    listField(step) {
      let fieldObject = [];

      const fields = new Set(); // Create a new Set to store unique values
      this.testScenarioRunFieldValues
        .filter((f) => f.TestScenarioRunStepsId == step)
        .forEach((f2) => (f2.Field ? fields.add(f2.Field) : null));
      fieldObject.push(fields);
      return Array.from(fields);
    },
    listFieldValues(step, field) {
      let values = this.testScenarioRunFieldValues.filter(
        (f) =>
          (f.Field ? f.Field == field : null) &&
          (f.TestScenarioRunStepsId ? f.TestScenarioRunStepsId == step : null)
      );

      return values;
    },
  },
  computed: {
    allExpanded() {
      return this.expandedRows.length === this.testScenarioRunSteps.length;
    },
    testScenarioRunStepsParams() {
      return {
        query: { TestScenarioRunDetailId: this.testScenarioRunDetail.id },
      };
    },
    testScenarioRunFieldValuesParams() {
      return {
        query: { TestScenarioRunDetailId: this.testScenarioRunDetail.id },
      };
    },
    groupParams() {
      return {};
    },
    exportQueriesParams() {
      return {
        query: { type: 'testHistory', id: this.testScenarioRun.id },
      };
    },
    testDetailLinesParams() {
      return {
        query: {
          TestScenarioRunId: this.testScenarioRunDetail.TestScenarioRunId,
          $sort: { Order: 1 },
        },
      };
    },

    processUrl() {
      let url = '';
      if (this.testScenarioRunDetail.process) {
        url =
          'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
          Date.now() +
          '/' +
          this.$appConfig.customer.prefix +
          '/' +
          this.testScenarioRunDetail.process.ProcessNumber.toString() +
          '.' +
          this.testScenarioRunDetail.process.VariantNumber.toString();
      }
      return url;
    },
    drawingUrl() {
      if (this.testScenarioRunDetail.process.NrOfDrawings > 0) {
        return `${this.$appConfig.clientBaseUrl}/#/drawing?variant=${this.testScenarioRunDetail.process.VariantId}`;
      } else {
        return null;
      }
    },

    lucidUrl() {
      if (this.testScenarioRunDetail.process && this.embedToken) {
        return `https://lucid.app/documents/${this.testScenarioRunDetail.process.ExternalDocumentId}/viewer?pages=1
        &token=${this.embedToken}`;
      }
      return null;
    },
  },
  async mounted() {
    this.loading = true;

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.list {
  background-color: transparent !important;
}
span.circle {
  // background: red;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 2px;
  text-align: center;
  width: 1.6em;
}

span.pointer {
  cursor: pointer;
}

.small {
  font-size: small !important;
}
</style>
